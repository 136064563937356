import React from 'react';

import App from '../../components/App';
import { EmptyPage } from '../../components/Common/styled/EmptyPage';
import { ProtocolAddView } from '../../components/Protocols/ProtocolAddView';

export default () => (
  <App>
    <EmptyPage>
      <ProtocolAddView />
    </EmptyPage>
  </App>
);
